@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "../stylesheets/magic_link.scss";
.new-ui {
  /* We need to reset all properties that are set in the body */
  font-size: 16px;
  font-family: Lato;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

#contact_notes_field {
  position: absolute;
  left: -9999px;
  z-index: -1;
}

@layer components {
  .form-group {
    @apply mb-4;
  }

  .form-input {
    @apply block w-full bg-white bg-opacity-10 p-3 text-lg text-white transition duration-200;
    box-sizing: border-box;
  }

  .form-input:focus {
    @apply outline-none bg-opacity-25;
  }

  .form-input::placeholder {
    @apply text-white text-opacity-75;
  }

  .form-error {
    @apply hidden text-sm text-light-red mt-2;
  }

  .container {
    @apply w-full mx-auto px-4;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1320px;
    }
  }

  .alert.alert-danger {
    @apply flex items-center bg-red text-white pl-6 pr-20 py-5 text-sm font-black;
  }

  .alert .close {
    @apply absolute right-5 text-xl font-black px-3 py-1 rounded-sm;
  }
}
