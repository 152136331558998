.or-line {
  max-width: 100%;
  margin: 20px auto;
  padding-bottom: 20px;
}

.or-hr-text {
  margin: 0px;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #ffffff;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: "";
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: #ffffff;

    padding: 0 0.5em;
    line-height: 1.5em;
    color: #ffffff;
    background-color: #333333;
  }
}

.underline-link {
  text-decoration-line: underline;
}

.button-display {
  width: 48%;
  min-width: 150px;
  overflow-wrap: break-word;
  border: 2.5px solid #3874ea;
}
